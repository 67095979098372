import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { AuthServiceJWT } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { WalletserviceService } from '../../services/walletservice.service';
import { CUSTODY_TYPE, orgType } from '@entities/members';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { environment } from 'src/environments/environment';
import { SidebarService } from './sidebar.service';
import { isCustodyOrg, isSdkEnabledForCustodyOrg } from '@helpers/org.utils';
import { TeamsService } from '../../services/teams.service';
import { TeamsV2, ComplianceTeamData } from '@entities/teams.model';
import { forkJoin, catchError, of, takeUntil, Subject } from 'rxjs';
import { getUserProfile } from '@entities/Policy';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  onDestroy$: Subject<void> = new Subject();
  profile:any = [];
  user: getUserProfile;
  active:boolean =  false;
  orgType = orgType
  custodyType = CUSTODY_TYPE
  loading = true
  isOwner: boolean = false;
  isComplianceMember: boolean = false;
  devDefaultRouting = '/dev/configure'
  isCustodyOrganization: boolean = false;
  constructor(public authService: AuthServiceJWT,private data: DataService, public appService: AppService,public httpService: HttpService, public walletService: WalletserviceService,private router: Router, private sidenavService: SidebarService, private teamsService: TeamsService) {
    this.getProfile();
    // this.walletService.currentViewWallet.subscribe(view => {
    //   console.log('view',view);
    //    this.active=view;
    // })
  }
  isSidenavOpen: boolean = false;

  ngOnInit() {
    if (environment.disableTennetBuildFts) {
      this.devDefaultRouting = '/dev/gas-station';
    }
  }

  async getProfile()
  {
    try{
      let profileData = this.data.getUserProfile.getValue();
      this.user = profileData;
      this.profile = profileData.organizations[0];
      this.isCustodyOrganization = isCustodyOrg(this.profile);
      this.isOwner = profileData.organizations[0].isowner;
      this.checkCompliance();
    if(profileData){
      this.loading = false
    }
    }catch(err){
      this.loading = false
    }
    
  }


  manipulateView(){
    this.appService.changeView(true)
    this.appService.changeTokenView(true)
  }

  isWalletActive(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/wallet') && !currentUrl.includes('/staking') ;
  }

  isStakingActive(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/wallet') && currentUrl.includes('/staking') ;
  }

  onClickNavItem(){
    this.sidenavService.close();
  }

  showDevTab(){
    return !this.isCustodyOrganization || (this.isCustodyOrganization && isSdkEnabledForCustodyOrg(this.profile))
  }

  checkCompliance(){ 
    forkJoin({
      teams: this.teamsService.getAllTeams(),
      complianceTeam: this.teamsService.getComplianceTeam().pipe(catchError((error) => (of(null))))
    }).pipe(takeUntil(this.onDestroy$))
    .subscribe(({teams, complianceTeam}: {teams: {teamListData: TeamsV2[]}, complianceTeam: (ComplianceTeamData | null)}) => {
      console.log('teams',teams);
      console.log('complianceTeam',complianceTeam);
      const team = teams.teamListData.find((team) => team.id === complianceTeam?.teamId);
      this.isComplianceMember = team?.teamMembers?.find((member) => member.userId === this.user.id) ? true : false;
    });
  }

  ngOndestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}