import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { AuthServiceJWT } from './shared/services/auth.service';
import { getParameterByName } from 'src/app/shared/helpers/general';
import { LoggerService } from './shared/services/logger.service';
import OverrideConsole from './shared/services/console.override';
import { environment } from '../environments/environment';
import { NavigationStart, Router } from '@angular/router';
import { OrganizationService } from './shared/services/organization.service';
import './shared/utils/userguiding';

//@ts-ignore
window.walletconnectweb3wallet = window['@walletconnect/web3wallet'];
//@ts-ignore
window.walletconnectCore = window['@walletconnect/core'];
//@ts-ignore
window.walletconnectUtils = window['@walletconnect/utils'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  refreshed = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private authService: AuthServiceJWT,
    logger: LoggerService,
    private router: Router,
    private orgSvc: OrganizationService,
  ) {
    if (
      getParameterByName('message') ==
      'Your email was verified. You can continue using the application.'
    ) {
      this.authService.clearLoginData();
    }
    if (environment.env !== 'dev') {
      OverrideConsole(logger);
    }
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart &&
        event.navigationTrigger === 'imperative' &&
        !this.refreshed
      ) {
        this.refreshed = true;
        if (sessionStorage?.getItem('rd_popup') == 'true') {
          localStorage.removeItem('rd_open');
        }
      }
    });
  }

  ngOnInit() {
    window.addEventListener('storage', this.orgSvc.onStorageChange);
  }
}
