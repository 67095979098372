import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../shared/services/http.service";
import { AuthServiceJWT } from "../../shared/services/auth.service";
import { AuthService } from "@auth0/auth0-angular";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { SegmentService } from 'ngx-segment-analytics';
import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";
@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  supportUrl = environment.auth0SupportUrl;
  buildType = environment.buildType;
  success: boolean = false;
  resend = true;
  timeLeft = 10;
  authDetails = null;
  userDetails: any;
  loading: boolean = false;

  constructor(
    private segment: SegmentService,
    public router: Router,
    private httpService: HttpService,
    public authService: AuthServiceJWT,
    public afAuth: AuthService,
  ) {
    this.authDetails = this.authService.getUser;
  }

  ngOnInit(): void {
    console.log('dc',this.authDetails);
  }

  async resendEmail() {
    this.resend = false;
    this.timer();
    Swal.fire({
      text: "Email verification sent. Kindly check your email address.",
      icon: "success",
      timer: 2500,
      showConfirmButton: false,
    });
    await this.httpService
      .resendEmailVerification({
        email: this.authDetails.email,
        userid: this.authDetails.sub,
        iss: this.authDetails.iss,
      })
      .toPromise();
  }

  timer() {
    this.timeLeft = 10;
    let interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
        clearInterval(interval);
        this.resend = true;
      }
    }, 1000);
  }

  logout() {
    this.authService.logout();
  }
}
