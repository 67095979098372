import { Component, Input, OnInit } from '@angular/core';
import BN from "bignumber.js";

@Component({
  selector: 'app-frozen-pending-tooltip-balance',
  templateUrl: './frozen-pending-tooltip-balance.component.html',
  styleUrls: ['./frozen-pending-tooltip-balance.component.scss']
})
export class FrozenPendingTooltipBalanceComponent implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
  }

  sumofwallets(wallet) {
    let total = new BN(0);
    if (wallet && wallet.length > 0)
      for (let i = 0; i < wallet.length; i++) {
        total = total.plus(new BN(wallet[i].balanceUSD));
      }
    return total
  }

  sumofPending(wallet) {
    let total = new BN(0);
    if (wallet && wallet.length > 0)
      for (let i = 0; i < wallet.length; i++) {
        total = total.plus(new BN((wallet[i].unspendableBalances?.pendingUsd)?wallet[i].unspendableBalances.pendingUsd:0));
      }
    return total
  }

  sumofFrozen(wallet) {
    let total = new BN(0);
    if (wallet && wallet.length > 0)
      for (let i = 0; i < wallet.length; i++) {
        total = total.plus(new BN((wallet[i].unspendableBalances?.frozenUsd)?wallet[i].unspendableBalances.frozenUsd:0));
      }
    return total
  }

}
