<span class="ml-2 cursor-pointer" placement="left" [ngbTooltip]="assetsBalance" tooltipClass="frozen-balance-tooltip" placement="bottom">
    <i class="icon-info" style="font-size: 14px!important;"></i>
</span>
<ng-template #assetsBalance>
    <div class="d-flex flex-column text-left">
        <div class="d-flex">
            <div class="d-flex flex-column p-2 same-width">
                <span class="font-size-12">Spendable Balance</span> 
                <span class="font-weight-500">${{ (sumofwallets(data) - sumofFrozen(data) - sumofPending(data)) | amountToPretty : 2 }}</span>
            </div>
            <div class="d-flex flex-column p-2">
                <span class="font-size-12">Pending Balance</span> 
                <span class="font-weight-500">${{ sumofPending(data) | amountToPretty : 2 }} </span>
            </div>
        </div>
        <div class="d-flex">
            <div class="d-flex flex-column p-2 same-width">
                <span class="font-size-12">Quarantined balance</span>
                <span class="font-weight-500">${{ sumofFrozen(data) | amountToPretty : 2}} </span>
            </div>
            <div class="d-flex flex-column p-2">
                <span class="font-size-12">Total balance</span>
                <span class="font-weight-500">${{sumofwallets(data) | amountToPretty : 2}} </span>
            </div>
        </div>
    </div>
</ng-template>