import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthServiceJWT } from './auth.service';
import { Observable} from 'rxjs';
import { ErrorPrompt } from '../components/custom-prompt/custom-prompt.service';
import { ErrorPromptPayload } from '../constants/promptMessages';
import { environment } from '../../../environments/environment.predev';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthServiceJWT,
    private orgSvc: OrganizationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // expire token one hr before it actually expires
    if (new Date().getTime() > this.auth.idTokenExpiry - 1000 * 60 * 60) {
      console.error('token expired, logout');
      ErrorPrompt.fire(ErrorPromptPayload.JWT_EXPIRED, () => {
        this.auth.clearLoginData();
      });
      return;
    }
    let authHeader = request.headers.get('Authorization');
    const noHeader = request.headers.get('NO-HEADER');
    const liminalAppVersion: string = environment.liminalAppVersion;

    if (noHeader) {
      request.headers.delete('NO-HEADERs');
    } else {
      if (!authHeader) {
        // get token and use it
        authHeader = `Bearer ${this.auth.getToken}`;
        request = request.clone({
          setHeaders: {
            Authorization: authHeader,
          },
        });
      } else if (authHeader === 'NA') {
        // use the one passed in request
        request.headers.delete('Authorization');
      }

      if (liminalAppVersion) {
        request = request.clone({
          setHeaders: {
            'x-lmnl-app-version': liminalAppVersion,
          },
        });
      }
      if (request.url.indexOf('users/my-orgs') === -1) {
        const currentOrg = this.orgSvc.getCurrentOrg();
        if (currentOrg && currentOrg?.orgId) {
          request = request.clone({
            setHeaders: {
              'x-lmnl-org': currentOrg.orgId.toString(),
            },
          });
        }
      }

      request.headers.append('Accept', 'application/json');
    }
    return next.handle(request);
  }
}
