import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { SegmentService } from 'ngx-segment-analytics';
@Component({
  selector: "app-apisigner",
  templateUrl: "./apisigner.component.html",
  styleUrls: ["./apisigner.component.scss"],
})
export class ApisignerComponent implements OnInit {
  public safeSrc: SafeResourceUrl;

  @Input() sdkEnabled: boolean;
  expressUrl: SafeResourceUrl;
  constructor(private segment: SegmentService, private modalService: NgbModal, private sanitizer: DomSanitizer) {
    // this.expressUrl = environment.expressUrl;
  }

  ngOnInit(): void {
    this.expressUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.expressUrl);
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  open(content) {
    this.modalService.open(content, {
      windowClass: "modal-custom-background",
      centered: true,
    });
  }
  registerClick() {
    this.segment.track("express-stack-launched").catch((err)=>{});
  }
}
