import { Routes } from '@angular/router';
import { OrgAuthGuard } from 'src/app/org-auth-guard.guard';


export const content: Routes = [
  {
    path: '',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'wallets',
    loadChildren: () => import('../../components/wallet/wallet.module').then(m => m.WalletModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'tx',
    loadChildren: () => import('../../components/transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'apps',
    loadChildren: () => import('../../components/app-details/app-details.module').then(m => m.AppDetailsModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'dev',
    loadChildren: () => import('../../components/dev/dev.module').then(m => m.DevModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'compliance',
    loadChildren: () => import('../../components/compliance/compliance.module').then(m => m.ComplianceModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'footer',
    loadChildren: () => import('../../shared/components/footer/footer.component').then(m => m.FooterComponent)
  }
];
