<div class="card">
  <div class="card-body p-4">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="d-flex justify-content-between">
          <span class="d-block font-size-16 font-weight-600 mb-1"
            >Liminal Express Stack</span
          >
          <!-- <button class="btn btn-round btn-outline-primary btn-sm setup_btn" disabled>Not setup</button> -->
        </div>
        <span class="d-block text-dark"
          >Deploying hot wallets and API SDK in your environment has never been
          easier. With Liminal Express Stack you can securely set up your
          infrastructure in a few clicks and start building on top of Liminal
          hassle free.</span
        >
      </div>
      <button appDisableClick [disabled]="!sdkEnabled" class="btn btn-primary btn-round" (click)="open(apisigner)">
        Launch
      </button>
    </div>
  </div>
</div>

<ng-template #apisigner let-modal>
  <div class="modal-custom">
    <div class="modal-header">
      <h2 class="modal-title">
        <ng-container>API Signer</ng-container>
      </h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
      >
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body mx-3">
      <div class="row">
        <div class="col-12">
          <div class="mb-4 express_modal">
            <ul>
              <svg
                width="2"
                height="500"
                viewBox="0 0 2 300"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="svg_progressline"
              >
                <path
                  d="  M1.376 201.536L1.376 198.344H0.76L0.76 201.536H1.376ZM1.376 201.536L1.376 198.344H0.76L0.76 201.536H1.376ZM1.376 193.63L1.376 190.438H0.76L0.76 193.63H1.376ZM1.376 185.723L1.376 182.531H0.76L0.76 185.723H1.376ZM1.376 177.817L1.376 174.625H0.76L0.76 177.817H1.376ZM1.376 169.911L1.376 166.719H0.76L0.76 169.911H1.376ZM1.376 162.005L1.376 158.813H0.76L0.76 162.005H1.376ZM1.376 154.098L1.376 150.906H0.76L0.76 154.098H1.376ZM1.376 146.192L1.376 143H0.76L0.76 146.192H1.376ZM1.376 138.286L1.376 135.094H0.76L0.76 138.286H1.376ZM1.376 130.38L1.376 127.188H0.76L0.76 130.38H1.376ZM1.376 122.473L1.376 119.281H0.76L0.76 122.473H1.376ZM1.376 114.567L1.376 111.375H0.76L0.76 114.567H1.376ZM1.376 106.661L1.376 103.469H0.76L0.76 106.661H1.376ZM1.376 98.7547L1.376 95.5627H0.76L0.76 98.7547H1.376ZM1.376 90.8485L1.376 87.6565H0.76L0.76 90.8485H1.376ZM1.376 82.9422L1.376 79.7502H0.76L0.76 82.9422H1.376ZM1.376 75.036L1.376 71.844H0.76L0.76 75.036H1.376ZM1.376 67.1297L1.376 63.9377H0.76L0.76 67.1297H1.376ZM1.376 59.2235V56.0315H0.76V59.2235H1.376ZM1.376 51.3172V48.1252H0.76V51.3172H1.376ZM1.376 43.411V40.219H0.76V43.411H1.376ZM1.376 35.5047V32.3127H0.76V35.5047H1.376ZM1.376 27.5985V24.4065H0.76V27.5985H1.376ZM1.376 19.6922V16.5002H0.76V19.6922H1.376ZM1.376 11.786V8.594H0.76V11.786H1.376ZM1.376 3.87975V0.68775H0.76V3.87975H1.376Z"
                  fill="#ABABAB"
                />
              </svg>
              <div class="bullet_div">
                <div class="bullet"></div>
                <li class="mb-2" id="newlist">
                  Once you click “launch now,” you will be redirected to the AWS
                  console.
                </li>
              </div>
              <div class="bullet_div">
                <div class="bullet"></div>
                <li class="mb-2" id="newlist">
                  Once you log in, you will be asked to enter certain parameters
                  on the CloudFormation page to configure the stack.
                </li>
              </div>
              <div class="bullet_div">
                <div class="bullet"></div>
                <li class="mb-2" id="newlist">
                  You must have access to AWS console and have admin permission
                  to deploy the stack.
                </li>
              </div>
              <div class="bullet_div">
                <div class="bullet"></div>
                <li class="mb-2" id="newlist">
                  This stack will install the following components:
                  <ul class="nested_ul" lass="mb-2">
                    <li>MPC signer - Used for hot MPC wallets.</li>
                    <li>KMS - Used for multi-sig hot wallets.</li>
                    <li>Liminal Express - Liminal’s API SDK</li>
                  </ul>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <a (click)="registerClick()"
        class="btn btn-primary w-100 mb-2"
        [href]="expressUrl"
        target="_blank"
        type="submit"
        class="btn btn-primary w-100 mb-2"
        >Launch Now</a
      >
    </div>
  </div>
</ng-template>
